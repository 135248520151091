import { Routes, Route } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./app/guards";
import { lazy } from "react";

const Login = lazy(() => import("./pages/login"));

const Dashboard = lazy(() => import("./pages/dashboard"));
const Volunteers = lazy(() => import("./pages/volunteers"));
const Donors = lazy(() => import("./pages/donors"));
const Educators = lazy(() => import("./pages/educators"));
const Viewpackets = lazy(() => import("./pages/packets/view"));
const Packets = lazy(() => import("./pages/packets"));
const Notifications = lazy(() => import("./pages/notifications"));
const Admin = lazy(() => import("./pages/admin"));
const Admins = lazy(() => import("./pages/admins"));
const Editforms = lazy(() => import("./pages/editforms"));
const Editformsview = lazy(() => import("./pages/editformsview"));

const RequiredItemList = lazy(() =>
  import("./pages/requiredItemManagement/rquiredItemList")
);
const AddRequiredItem = lazy(() =>
  import("./pages/requiredItemManagement/addRequiredItem")
);
const EditRequiredItem = lazy(() =>
  import("./pages/requiredItemManagement/editRequiredItem")
);
const CountyList = lazy(() => import("./pages/countyManagement/countyList"));
const AddCounty = lazy(() => import("./pages/countyManagement/addCounty"));
const EditCounty = lazy(() => import("./pages/countyManagement/editCounty"));
const AddSchoolDistrict = lazy(() =>
  import("./pages/schoolDistrictManagement/addSchoolDistrict")
);
const SchoolDistrictList = lazy(() =>
  import("./pages/schoolDistrictManagement/schoolDistrictList")
);
const EditSchoolDistrict = lazy(() =>
  import("./pages/schoolDistrictManagement/editSchoolDistrict")
);
const AddItemSize = lazy(() =>
  import("./pages/itemSizeManagement/addItemSize")
);
const EditItemSize = lazy(() =>
  import("./pages/itemSizeManagement/editItemSize")
);
const ItemSizeList = lazy(() =>
  import("./pages/itemSizeManagement/itemSizeList")
);
const AddSchool = lazy(() => import("./pages/schoolManagement/addSchool"));
const EditSchool = lazy(() => import("./pages/schoolManagement/editSchool"));
const SchoolList = lazy(() => import("./pages/schoolManagement/schoolList"));
const PartnerList = lazy(() => import("./pages/partnerManagement/partnerList"));
const AddPartner = lazy(() => import("./pages/partnerManagement/addPartner"));
const EditPartner = lazy(() => import("./pages/partnerManagement/editPartner"));
const ViewPartnerVolunteers = lazy(() =>
  import("./pages/partnerManagement/viewPartnerVolunteers")
);
const FormPlaceholderManagement = lazy(() =>
  import("./pages/formPlaceholderManagement")
);
const EditFormPlaceholders = lazy(() =>
  import("./pages/formPlaceholderManagement/edit/EditFormPlaceholders")
);
const UnassignedPacketsListing = lazy(() =>
  import("./pages/packets/unassigned")
);
const ActivePacketsListing = lazy(() => import("./pages/packets/active"));
const ClosedPacketsListing = lazy(() => import("./pages/packets/closed"));
const ChangePassword = lazy(() => import("./pages/change-password"));
const ForgottenPassword = lazy(() => import("./pages/forgotten-password"));
const EditPacket = lazy(() => import("./pages/packets/edit"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgottenPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Dashboard />} />
        <Route
          path="/required-item-management"
          element={<RequiredItemList />}
        />
        <Route
          path="/required-item-management/add"
          element={<AddRequiredItem />}
        />
        <Route
          path="/required-item-management/edit/:id"
          element={<EditRequiredItem />}
        />
        <Route path="/county-management" element={<CountyList />} />
        <Route path="/county-management/add" element={<AddCounty />} />
        <Route path="/county-management/edit/:id" element={<EditCounty />} />
        <Route
          path="/school-district-management"
          element={<SchoolDistrictList />}
        />
        <Route
          path="/school-district-management/add"
          element={<AddSchoolDistrict />}
        />
        <Route
          path="/school-district-management/edit/:id"
          element={<EditSchoolDistrict />}
        />
        {/*<Route path="/item-size-management" element={<ItemSizeList />} />*/}
        {/*<Route path="/item-size-management/add" element={<AddItemSize />} />*/}
        {/*<Route*/}
        {/*  path="/item-size-management/edit/:id"*/}
        {/*  element={<EditItemSize />}*/}
        {/*/>*/}
        <Route path="/school-management" element={<SchoolList />} />
        <Route path="/school-management/add" element={<AddSchool />} />
        <Route path="/school-management/edit/:id" element={<EditSchool />} />
        <Route path="/partner-management" element={<PartnerList />} />
        <Route path="/partner-management/add" element={<AddPartner />} />
        <Route path="/partner-management/edit/:id" element={<EditPartner />} />
        <Route
          path="/partner-management/view-volunteers/:id"
          element={<ViewPartnerVolunteers />}
        />
        <Route path="/volunteers" element={<Volunteers />} />
        <Route path="/educators" element={<Educators />} />

        <Route
          path="/form-placeholder-management"
          element={<FormPlaceholderManagement />}
        />
        <Route
          path="/form-placeholder-management/edit/:type"
          element={<EditFormPlaceholders />}
        />

        <Route path="/requests" element={<Packets />} />
        <Route
          path="/requests/unassigned"
          element={<UnassignedPacketsListing />}
        />
        <Route path="/requests/active" element={<ActivePacketsListing />} />
        <Route path="/requests/closed" element={<ClosedPacketsListing />} />
        <Route path="/requests/details/:packetId" element={<Viewpackets />} />
        <Route path="/requests/edit/:packetId" element={<EditPacket />} />
      </Route>
      <Route path="/donors" element={<Donors />} />
      <Route path="/viewrequests" element={<Viewpackets />} />
      <Route path="/requests" element={<Packets />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/admins" element={<Admins />} />
      <Route path="/editforms" element={<Editforms />} />
      <Route path="/editformsview" element={<Editformsview />} />
    </Routes>
  );
};

export default AppRoutes;
