/* eslint-disable import/no-anonymous-default-export */
export default {
  auth: {
    login: "/auth/login",
    renewToken: "/auth/renew-token",
    logout: "/auth/sign-out-all-sessions",
    confirmAccount: "/auth/confirm-account",
    changePassword: "/auth/change-password",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password",
    meData: "/auth/me",
  },
  itemTypeManagement: {
    add: "/item-type/create-item-type",
    edit: (id) => `/item-type/update-item-type/${id}`,
    getAll: "/item-type/get-item-types",
    getSingle: (id) => `/item-type/get-item-type/${id}`,
    delete: (id) => `/item-type/delete-item-type/${id}`,
  },
  itemSizeManagement: {
    add: "/item-size/create-item-size",
    edit: (id) => `/item-size/update-item-size/${id}`,
    getAll: "/item-size/get-item-sizes",
    getSingle: (id) => `/item-size/get-item-size/${id}`,
    delete: (id) => `/item-size/delete-item-size/${id}`,
  },
  countyManagement: {
    add: "/county/create-county",
    edit: (id) => `/county/update-county/${id}`,
    getAll: "/county/get-counties",
    getSingle: (id) => `/county/get-county/${id}`,
    delete: (id) => `/county/delete-county/${id}`,
  },
  schoolDistrictManagement: {
    add: "/school-district/create-school-district",
    edit: (id) => `/school-district/update-school-district/${id}`,
    getAll: "/school-district/get-school-districts",
    getSingle: (id) => `/school-district/get-school-district/${id}`,
    delete: (id) => `/school-district/delete-school-district/${id}`,
    setSchoolDistrictStatus: (schoolDistrictId) =>
      `/school-district/change-school-district-status/${schoolDistrictId}`,
  },
  schoolManagement: {
    add: "/school/create-school",
    edit: (id) => `/school/update-school/${id}`,
    getAll: "/school/get-schools",
    getSingle: (id) => `/school/get-school/${id}`,
    delete: (id) => `/school/delete-school/${id}`,
  },
  partnerManagement: {
    add: "/partner/create-partner",
    edit: (id) => `/partner/update-partner/${id}`,
    getAll: "/partner/get-partners",
    getSingle: (id) => `/partner/get-partner/${id}`,
    delete: (id) => `/partner/delete-partner/${id}`,
  },
  formPlaceholderManagement: {
    getAll: `/form-placeholder/get-form-placeholders`,
    getParticularFormPlaceholder: `/form-placeholder/get-form-placeholder/:formPlaceholderId`,
    updateParticularFormPlaceholder: (formPlaceholderId) =>
      `/form-placeholder/update-form-placeholder/${formPlaceholderId}`,
    createFormPlaceholder: `/form-placeholder/create-form-placeholder`,
    delete: (formPlaceholderId) =>
      `/form-placeholder/delete-form-placeholder/${formPlaceholderId}`,
  },
  packetManagement: {
    getAll: `/packet/get-packets`,
    getParticularPacket: (packetId) => `/packet/get-packet/${packetId}`,
    deletePacket: (packetId) => `/packet/delete-packet/${packetId}`,
    updatePacket: (packetId) => `/packet/update-packet/${packetId}`,
    updateGiftCard: (packetId) => `/packet/update-gift-card/${packetId}`,
    updateTotalSpend: (packetId) =>
      `/packet/update-total-spend-amount/${packetId}`,
  },
  volunteer: {
    getAll: "/volunteer/get-volunteers",
    getAvailableVolunteers: (packetId) =>
      `/volunteer/get-available-volunteers/${packetId}`,
    getSingle: (id) => `/volunteer/get-volunteer/${id}`,
    delete: (id) => `/volunteer/delete-volunteer/${id}`,
    assignPacket: (packetId) => `/packet/assign-packet/${packetId}`,
    export: () => `/volunteer/export-volunteers`,
    setBlacklistedStatus: "/volunteer/set-blacklisted-status",
  },
  educator: {
    getAll: "/educator/get-educators",
    getSingle: (id) => `educator/get-educator/${id}`,
    delete: (id) => `/educator/delete-educator/${id}`,
    export: () => `/educator/export-educators`,
  },
  stats: {
    getReports: `/report/get-report`,
    getExportedData: `/report/export-report`,
  },
  subadmin: {
    getAll: `/admin/get-admins`,
    create: `/admin/create-admin`,
    update: (cognitoUserId) => `/admin/update-admin/${cognitoUserId}`,
    delete: (adminId) => `/admin/delete-admin/${adminId}`,
  },
  notifications: {
    getAll: "/notification/get-notifications",
    getSentNotifications: "/notification/get-admin-sent-notifications",
    sendNotification: "/notification/send-notifications-to-user-types",
  },
  partner: {
    getAll: "/partner/get-partners",
    getAvailablePartners: (packetId) =>
      `/partner/get-available-partners/${packetId}`,
    assignPacket: (packetId) => `/packet/assign-partner/${packetId}`,
  },
};
